import React, { Component } from "react";
import { Link } from 'gatsby';

import Desktop_Navigation_Link from "./Desktop_Nav--link-dark.js";



export default class DesktopHeader extends Component {
	constructor(props){
		super(props);
		this.state = {
			interactionThrottle: null,
			timer: 300,
			sub_menu_shown: false,
			hovering_link: false,
			show_background: '',
			blendClass: ''
		};
	}
	componentDidMount() {
		document.body.classList.remove('dark-text')
		this.setState({ show_background: '' })
		if (window.location.pathname === '/about') this.setState({ blendClass: 'about-color' })
		if (window.location.pathname === '/contact/new-business' || document.body.classList.contains('dark-text')) this.setState({ show_background: 'show-background-white' })
		else this.setState({ blendClass: null })

		document.addEventListener('scroll', this.scroll_handler);
	}
	componentWillUnmount() {
		document.removeEventListener('scroll', () => this.scroll_handler());
	}
	interaction = () => {
		clearTimeout(this.state.interactionThrottle)
		this.setState({
			hovering_link: true
		});
	}
	uninteraction = () => {
		this.state.interactionThrottle = setTimeout(()=>{
			this.setState({
				hovering_link: false
			})
		}, this.state.timer);
	}
	scroll_handler = () => {
		if (window.pageYOffset > 80 && this.state.show_background === '') this.setState({ show_background: 'show-background' })
		else if (window.pageYOffset <= 80 && this.state.show_background === 'show-background') this.setState({ show_background: '' })
	}
	render_links = () => {
		if(this.props.links){
			const items = this.props.links[0].items;
			const $finalElements = [];
			return items.map( ( link, i ) => <Desktop_Navigation_Link key={ i } title={link.title} url={link.url} classes={link.classes} parentInteraction={this.interaction} parentUninteraction={this.uninteraction} /> );
		}
	}
	render(){
		let hoverClass = this.state.hovering_link ? " links-hovered" : " normal";
		let subMenuClass = this.state.sub_menu_shown ? " display" : " dont-display";
		return <React.Fragment>
			<div className={"desktop-navigation" + hoverClass + ' ' + this.state.show_background + ' ' + this.state.blendClass}>
				<div className="grid">
					<ul className="grid-m-6 grid-t-16 grid-d-16">
						{this.render_links()}
					</ul>
				</div>
			</div>
		</React.Fragment>;
	}
}

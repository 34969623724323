import "isomorphic-fetch"

import React, { Component } from 'react';
import { request_page } from "../../components/data/fetch_data.js";
import TitleDescription from "../../components/Contact/Title_Description.js";
import Footer from "../../components/Footer/Footer.js";
import MessageSent from "../../components/New_Business/Message_Sent.js";
import Navigation from "../../components/Header/Navigation-dark.js";
import GDPR from '../../components/Global/GDPR';
import PageTransition from 'gatsby-plugin-page-transitions';
// import Loader from '../../components/Global/Loader';
import SEO from '../../components/seo.js';


const formId = 994;
const apiDomain = 'https://api.thecharlesnyc.com';

const css = `
	.mobile-header.show-background,
	.desktop-navigation::after{
		background: #fff;
	}
	.desktop-navigation span,
	.desktop-navigation a{
		color: #252525;
	}
	.desktop-navigation a::after{
		background: #252525;
	}
	.desktop-navigation li.center-logo svg{
		fill: #252525
	}

	.mobile-header .center-logo svg {
		fill: #252525 !important;
	}

	.mobile-header .hamburger-icon .bar::before {
		background: #252525 !important;
	}
`;

class TestBusiness extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
			formisLoaded: false,
			formHtml: null,
			messageSent: false
		};
		this.state.data[0].acf['hero_description'] = " Want to create something beautiful? <br/>Fill out the details below.";
	}
	
	componentDidMount() {
		
		const script = document.createElement("script");
		script.setAttribute("charset", "utf-8");
		script.setAttribute("type", "text/javascript");
		script.src = "https://js.hsforms.net/forms/embed/v2.js";
		script.async = false;
		
		// Only create the form _after_ the Hubspot JS is loaded.
		script.onload = () => {
			if (typeof hbspt !== 'undefined') {
				hbspt.forms.create({
					portalId: "44050800",
					formId: "a6707097-5e24-4bd6-9d13-41a25f26927f",
					target: '.form-container',
					css: "color:red"
				});
			}
		};
		
		document.head.appendChild(script);
		
		setTimeout(() => { document.querySelector('.page.new-business').classList.remove('fade-enter-done'); }, 1000)
	}
	
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? " page-ready page-loaded show-text" : " page-loading";
		if(this.state.isLoaded){
			
			return(
				<PageTransition>
				<div class="site-contain">
				<GDPR data={this.state.cookieBanner} />
				<SEO
				title={this.state.data[0].yoast_meta.yoast_wpseo_title}
				description={this.state.data[0].yoast_meta.yoast_wpseo_metadesc}
				/>
				<Navigation links={this.state.headerLinks} />
				<div className={"page new-business" + page_ready}>
				<style>{css}</style>
				{this.state.messageSent ? (<MessageSent />) : (
					<React.Fragment>
					<TitleDescription data={this.state.data} />
					<div className="form-container grid">
					
					</div>
					</React.Fragment>
				)}
				<Footer links={this.state.footerLinks} />
				</div>
				</div></PageTransition>);
			} else{
				return( <div></div> );
			}
		}
	}
	
	export default TestBusiness;
	